import styled from "@emotion/styled"
import { Trans, useTranslation } from "next-i18next"

import Container from "@components/Container"
import Link from "@components/Link"
import Head from "@components/Head"
import NotFoundImage from "./images/404.svg"

const StyledContainer = styled(Container)`
    min-height: inherit;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 100px 50px;

    @media screen and (max-width: 768px) {
        padding: 20px;
        flex-direction: column;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

    margin-top: -20px;

    @media screen and (max-width: 768px) {
        order: 2;
        text-align: center;
    }
`

const Title = styled.h1`
    color: ${({ theme }) => theme.colors.semantic.secondary[600]};
    font-size: 90px;
    margin: 0px;
`

const SubTitle = styled.h2`
    font-size: 24px;
    margin: 0px;
`

const Paragraph = styled.p`
    margin: 0px;

    a {
        color: ${({ theme }) => theme.colors.semantic.primary[500]};
    }
`

const ImageWrapper = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    flex: 2;

    @media screen and (max-width: 768px) {
        order: 1;
    }
`

const StyledLink = styled(Link)`
    display: inline-flex;
`

const Custom404 = () => {
    const { t } = useTranslation("pages")

    return (
        <StyledContainer>
            <Head title={t("custom_404.title")} description={t("custom_404.description")} />

            <Wrapper>
                <Title>404</Title>
                <SubTitle>{t("custom_404.description")}</SubTitle>
                <Paragraph>
                    <Trans i18nKey='custom_404.back_to_home' ns='pages'>
                        Periksa kembali link yang ingin kamu tuju atau kembali ke
                        <StyledLink href='/'>halaman utama</StyledLink>.
                    </Trans>
                </Paragraph>
            </Wrapper>
            <ImageWrapper>
                <NotFoundImage width='80%' />
            </ImageWrapper>
        </StyledContainer>
    )
}

export default Custom404
