import { serverSideTranslations } from "next-i18next/serverSideTranslations"

import Custom404 from "@app/Custom404"
import { defaultLanguage } from "@config/i18n"

export const getStaticProps = async ({ locale }: { locale: string }) => ({
    props: {
        ...(await serverSideTranslations(locale || defaultLanguage, ["components", "pages"]))
    }
})

export default Custom404
